import React, {useEffect, useState} from "react";
import { Provider } from "react-redux";
import { hot } from "react-hot-loader";
import configureStore, {
  history,
  sagaMiddleware,
} from "./config/store/configureStore";
import "./App.scss";
import MainLayout from "./layoutRouter/MainLayout/index";
import rootSaga from "./config/model/rootSaga";
import { StyleComponentConnected } from "./scss/StyleComponent";
import "./utils/Translations";
import { loadReCaptcha } from "react-recaptcha-v3";
import { RECAPTCHA_SITE_KEY } from "./config/constant/base";
//import translations from "./utils/translations/translations";
import { LangContextTheme } from './context/langContext';
import {Helmet} from "react-helmet";

export const store = configureStore();

sagaMiddleware.run(rootSaga);

const generateNonce = () => {
  return btoa(String.fromCharCode(...crypto.getRandomValues(new Uint8Array(16))));
};

const App =()=> {
  const [nonce, setNonce] = useState("");
  useEffect(() => {
    setNonce(generateNonce());
  }, []);
  const cspContent = process.env.NODE_ENV === "development"
    ? `script-src 'self' 'strict-dynamic' 'unsafe-eval' https://www.recaptcha.net https://www.gstatic.com https://www.google.com;
     object-src 'none';
     frame-ancestors 'self';
     font-src 'self' https://www.gstatic.com https://cdn.paymentez.com;
     connect-src 'self' https://www.recaptcha.net https://dashboard-api-stg.paymentez.com;
     upgrade-insecure-requests;`:
    `script-src 'self' 'strict-dynamic'  https://www.recaptcha.net https://www.gstatic.com https://www.google.com;
     frame-ancestors 'self';
     font-src 'self' https://www.gstatic.com https://cdn.paymentez.com;
     connect-src 'self' https://www.recaptcha.net https://dashboard-api-stg.paymentez.com;
     upgrade-insecure-requests; object-src 'none'; `

  //const noncefijo ="Dh6qYOX3VTvrevMQGPCDSA=="

  return (
      <Provider store={store}>
        <Helmet>
          <meta httpEquiv="Content-Security-Policy" content={cspContent}/>
          <script async defer nonce={nonce} src={`https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`}/>
        </Helmet>
        <LangContextTheme>
          <StyleComponentConnected/>
          <MainLayout history={history}/>
        </LangContextTheme>
      </Provider>
    );

}

export default hot(module)(App);
